import { render, staticRenderFns } from "./wk_4aus5.vue?vue&type=template&id=2d845c92&scoped=true"
import script from "./wk_4aus5.vue?vue&type=script&lang=js"
export * from "./wk_4aus5.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2d845c92",
  null
  
)

export default component.exports
<template>
  <v-card flat>
    <table>
      <colgroup>
        <col style="width:34%" />
        <col style="width:13%" />
        <col />
        <col style="width:13%" />
        <col style="width:34%" />
      </colgroup>
      <tr class="head">
        <td colspan="2" class="heim">{{ h.team.name }}</td>
        <td></td>
        <td colspan="2">{{ g.team.name }}</td>
      </tr>
      <tr v-for="(d, i) in d" :key="d._discipline" :class="{even: i % 2 === 0}">
        <td class="heim">{{ d.name }}</td>
        <td class="heim ergebnis">{{ mannschaftgeraetergebnis(e, r, h.team._id, d._discipline) | float2_0 }}</td>
        <td></td>
        <td class="ergebnis">{{ mannschaftgeraetergebnis(e, r, g.team._id, d._discipline) | float2_0 }}</td>
        <td>{{ d.name }}</td>
      </tr>
      <tr>
        <td colspan="5">&nbsp;</td>
      </tr>
      <tr :class="{ergebnis:true, even: d.length % 2 === 0}">
        <td class="heim">Ergebnis gesamt</td>
        <td class="heim ergebnis">{{ mannschaftgesamtergebnis(e, r, h.team._id) | float2_0 }}</td>
        <td></td>
        <td class="ergebnis">{{ mannschaftgesamtergebnis(e, r, g.team._id) | float2_0 }}</td>
        <td>Ergebnis gesamt</td>
      </tr>
    </table>
  </v-card>
</template>

<script>
import { useCalc } from '../../../plugins/calc'

export default {
  name: 'wk_4aus5',

  setup (props, context) {
    return {
      ...useCalc(props, context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    h () {
      return this.heim(this.e)
    },
    g () {
      return this.gast(this.e)
    },
    d () {
      return this.geraete(this.e, this.df)
    }
  }
}
</script>

<style scoped>

</style>
